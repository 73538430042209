<script setup lang="ts">

</script>

<template>
  <div h-screen>
    <iframe src="https://xw.qq.com/" w-full h-full flex-1 />
  <TheFooter fixed bottom-0 h-50 w-full />
</div>
</template>
